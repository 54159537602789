import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


export default withStyles({
  spanLink: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})(function Labs({ classes }) {
  return (
    <div>
      <Typography variant='h5'>
        Hello!
      </Typography>
      <br />
      <Typography variant='h5'>
        <Box component='span' display='inline' fontWeight='fontWeightBold'><Link
          className={classes.spanLink}
          color='secondary'
          underline='none'
          href='/labs'
        >Korley Labs</Link></Box>{' '}
        is my software firm for full-stack <Box display='inline' fontWeight='fontWeightBold'>web development</Box> and <Box display='inline' fontWeight='fontWeightBold'>data engineering</Box> projects. It houses my indie hacker ventures as well as consulting work.
      </Typography>
      <br />
      <Typography variant='h5'>
        We use tools across the stack to build <Box display='inline' fontWeight='fontWeightBold'>accessible</Box>, <Box display='inline' fontWeight='fontWeightBold'>performant</Box>, and <Box display='inline' fontWeight='fontWeightBold'>reliable</Box> software. Keep an eye on this page as we take a few projects out of stealth soon!
      </Typography>
      <br />
      <Typography variant='h5'>
      If you have a problem in a business or non-profit domain that could be solved with code, please feel free to {' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
          <Link
            className={classes.spanLink}
            target='_blank'
            rel='noopener'
            color='secondary'
            underline='none'
            href='mailto:edmund@korley.net?subject=Hello'>
            reach out
          </Link>
        </Box>. Always happy to chat :-)
      </Typography>
      <Box mt={4} mb={2}>
        <Divider />
      </Box>
      <Box mb={2} fontWeight='fontWeightBold'>
        <Grid container justify='center' spacing={1}>
          <Grid item>
            <Typography variant='subtitle1'>
              <Link
                  target='_blank'
                  rel='noopener'
                  color='primary'
                  underline='none'
                  href='https://linkedin.com/company/korley-labs'>LinkedIn</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container justify='center'>
          <Typography variant='caption'>
            Copyright © 2020 Korley Labs, LLC. All Rights Reserved.
          </Typography>
        </Grid>
      </Box>
    </div>
  );
});
